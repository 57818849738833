<template>
  <div>
    <div v-if="groupShow == 1">
      <div class="zhibiao"
        v-for="item in xxList"
        :key="item.HId">
        <h4 style="padding-left:10px;">检测时间：{{item.AddTime}}</h4>
        <van-cell-group>
          <van-cell title="血压(高压)"
            :value="item.HighPressure" />
          <van-cell title="血压(低压)"
            :value="item.LowPressure" />
        </van-cell-group>
      </div>
    </div>
    <div v-if="groupShow == 3">
      <div class="zhibiao"
        v-for="item in xxList"
        :key="item.HId">
        <h4 style="padding-left:10px;">检测时间：{{item.AddTime}}</h4>
        <van-cell-group>
          <van-cell title="心率"
            :value="item.HeartRate+'(bpm)'" />
        </van-cell-group>
      </div>
    </div>

    <div v-if="groupShow == 4">
      <div class="zhibiao"
        v-for="item in xxList"
        :key="item.HId">
        <h4 style="padding-left:10px;">检测时间：{{item.AddTime}}</h4>
        <van-cell-group>
          <van-cell title="血糖"
            :value="item.BloodSugar+'(mmol/l)'" />
        </van-cell-group>
      </div>
    </div>

    <div v-if="groupShow == 2">
      <div class="zhibiao"
        v-for="item in xxList"
        :key="item.HId">
        <h4 style="padding-left:10px;">检测时间：{{item.AddTime}}</h4>
        <van-cell-group>
          <van-cell title="体温"
            :value="item.Temperature + '(℃)'" />
        </van-cell-group>
      </div>
    </div>

    <div v-if="groupShow == 6">
      <div class="zhibiao"
        v-for="item in xxList"
        :key="item.HId">
        <h4 style="padding-left:10px;">检测时间：{{item.AddTime}}</h4>
        <van-cell-group>
          <van-cell title="血脂"
            :value="item.Bloodfat" />
        </van-cell-group>
      </div>
    </div>

    <div v-if="groupShow == 7">
      <div class="zhibiao"
        v-for="item in xxList"
        :key="item.HId">
        <h4 style="padding-left:10px;">检测时间：{{item.AddTime}}</h4>
        <van-cell-group>
          <van-cell title="糖化血红蛋白"
            :value="item.AGES" />
        </van-cell-group>
      </div>
    </div>

    <div v-if="groupShow == 8">
      <div class="zhibiao"
        v-for="item in xxList"
        :key="item.HId">
        <h4 style="padding-left:10px;">检测时间：{{item.AddTime}}</h4>
        <van-cell-group>
          <van-cell title="血氧"
            :value="item.BloodOxygen" />
        </van-cell-group>
      </div>
    </div>
    <div v-if="groupShow == 9">
      <div class="zhibiao"
        v-for="item in xxList"
        :key="item.HId">
        <h4 style="padding-left:10px;">检测时间：{{item.AddTime}}</h4>
        <van-cell-group>
          <van-cell title="尿酸"
            :value="item.Urinalysis" />
        </van-cell-group>
      </div>
    </div>
    <div v-if="groupShow == 5">
      <div class="zhibiao"
        v-for="item in xxList"
        :key="item.HId">
        <h4 style="padding-left:10px;">检测时间：{{item.AddTime}}</h4>
        <van-cell-group>
          <van-cell title="体重"
            :value="item.Weight" />
        </van-cell-group>
      </div>
    </div>
    <van-empty description="暂无数据"
      v-if="wushuju" />
  </div>

</template>
<script>
import {
  WeGetElderlyBaseInfo,
  WePageHealthyList
} from "@/api/Pension";
import { setOpenId, getOpenId } from "@/utils/auth";
export default {
  data () {
    return {
      hId: "",
      name: "",
      iden: "",
      age: "",
      jkList: {},
      xxList: {},
      groupShow: "",
      wushuju: false,
      OpenID: '',
    }
  },
  created () {
    this.laorenXx();
    if (this.$route.query["openid"]) {
      setOpenId(this.$route.query["openid"]);
    }
  },
  methods: {
    laorenXx: function () {
      this.OpenID = getOpenId();
      WeGetElderlyBaseInfo({ openID: this.OpenID }).then((res) => {
        console.log(res);
        this.hId = res.data.data.HId;
        this.jiankangList(this.hId)

      })
    },
    jiankangList: function (hId) {
      var groupId = this.$route.params.groupId
      this.groupShow = this.$route.params.groupId
      console.log(groupId);
      WePageHealthyList({ hId: hId, group: groupId }).then((res) => {
        if (res.data.count == 0) {
          this.wushuju = true
        } else {
          console.log(res.data.data);
          this.xxList = res.data.data
          this.wushuju = false
        }

      })
    },
  }
}
</script>
<style scoped>
.zhibiao {
  position: relative;
  width: 90%;
  margin: 15px 5%;
  background: #fff;
  border-radius: 15px;
  border: 1px solid #f1eaea;
  padding-bottom: 10px;
}
</style>